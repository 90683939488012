@import 'main_setting';

$text_color: map-get($text, main_color);
$background_color: map-get($body, background);
$text_size: map-get($text, main_size);
$body_width: map-get($body, max_width);
$text_orange: map-get($color_theme, orange);

body {
	font-size: $text_size;
    color: $text_color;
    font-family: supermarket;
    background-color: $background_color;
    max-width: $body_width;
    margin: auto;
    -webkit-box-shadow: 0px 0px 3px 1px #ccc;
    -moz-box-shadow: 0px 0px 3px 1px #ccc;
    box-shadow: 0px 0px 3px 1px #ccc;

    a[href^="tel"]{
        color:inherit;
        text-decoration:none;
    }
}

h1, h2, h3, h4 {
    color: #ff5800;
}

.btn-custom {
    &, &:hover, &:focus {
        color: map-get($btn, text);
        background-color: map-get($btn, background);
    }

    &.btn-lg {
        padding: 10px 16px 6px;
        width: 160px;
    }
}
header {
    height: 100px;

    @media (max-width: 768px) {
        height: 30px;
        margin-bottom: 30px;
    }
}

.clearpadding {
    padding: 0;
}

.scrollup {
    width: 66px;
    height: 66px;
    position: fixed;
    bottom: 85px;
    right: 50px;
    display: none;
    text-indent: -9999px;
    z-index: 1000000;
    background: url('/img/gototop.png') no-repeat;
    background-color: transparent;
}

@media (max-width: 768px) {
    .scrollup {
        bottom: 35px;
        right: 30px;
    }
}

.btn-pcm {
    color: #fff;
    background-color: $text_orange;
    border-color: $text_orange;
}
.btn-pcm:hover {
    color: #fff;
    background-color: #ff7932;
    border-color: #ff7932;
}

@import 'main_setting';

$button-transistion-duration: 0.3s;     // The transition duration

.navbar-default {

    .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }

    &.navbar-custom {
        z-index: 1100;
        margin-bottom: 0;
        height: 55px;
        background-color: map-get($navbar, background);
        border-color: map-get($navbar, border);

        .navbar-collapse, .navbar-form {
            border-color: map-get($navbar, collapse_border);
            background-color: map-get($navbar, background);
        }

        .c-hamburger {
            display: block;
            position: relative;
            overflow: hidden;
            font-size: 0;
            text-indent: -9999px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            box-shadow: none;
            cursor: pointer;
            transition: background $button-transistion-duration;

            float: right;
            padding: 18px 20px;
            margin-top: 8px;
            margin-right: 15px;
            margin-bottom: 8px;
            background-color: map-get($navbar, toggle_background);
            background-image: none;
            border: 1px solid map-get($navbar, toggle_border);
            border-radius: 4px;
        }

        .c-hamburger:focus {
            outline: none;
        }

        .c-hamburger span {
            display: block;
            position: absolute;
            top: 17px;
            left: 9px;
            right: 10px;
            width: 22px;
            height: 2px;
            border-radius: 1px;
            background: map-get($navbar, toggle_icon);
        }

        .c-hamburger span::before,
        .c-hamburger span::after {
            position: absolute;
            display: block;
            left: 0;
            width: 22px;
            height: 2px;
            border-radius: 1px;
            background-color: map-get($navbar, toggle_icon);
            content: "";
        }

        .c-hamburger span::before {
            top: 7px;
        }

        .c-hamburger span::after {
            bottom: 7px;
        }

        /**
         * Style 2
         *
         * Hamburger to "x" (htx). Takes on a hamburger shape, bars slide
         * down to center and transform into an "x".
         */

        .c-hamburger--htx {
            background-color: transparent;
            margin-right: 15px;
        }

        .c-hamburger--htx span {
            transition: background 0s $button-transistion-duration;
        }

        .c-hamburger--htx span::before,
        .c-hamburger--htx span::after {
            transition-duration: $button-transistion-duration, $button-transistion-duration;
            transition-delay: $button-transistion-duration, 0s;
        }

        .c-hamburger--htx span::before {
            transition-property: top, transform;
        }

        .c-hamburger--htx span::after {
            transition-property: bottom, transform;
        }

        /* active state, i.e. menu open */

        .c-hamburger--htx.is-active {
            background-color: transparent;
        }

        .c-hamburger--htx.is-active span {
            background: none;
        }

        .c-hamburger--htx.is-active span::before {
            top: 0;
            transform: rotate(45deg);
        }

        .c-hamburger--htx.is-active span::after {
            bottom: 0;
            transform: rotate(-45deg);
        }

        .c-hamburger--htx.is-active span::before,
        .c-hamburger--htx.is-active span::after {
            transition-delay: 0s, $button-transistion-duration;
        }


        .navbar-nav {
            margin: 0 100px 0 0;
            @media (max-width: 768px) {
                margin: 0;
            }

            >li {
                >a {
                    @media (min-width: 769px) {
                        padding: 50px 20px 10px 20px;
                    }
                    @media (max-width: 768px) {
                        padding: 20px 20px 10px 20px;
                        border-bottom: 1px solid;
                    }
                    & {
                        color: map-get($navbar, text);
                        background-color: map-get($navbar, background);
                    }
                    &:focus, &:hover, &.active {
                        color: #fff;
                        background-color: map-get($color_theme, orange);
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .navbar-brand {
        padding: 5px 15px;
        img {
            width: 200px;
            margin-left: 15px;
        }
    }
}

@media (max-width: 768px) {

    .navbar-brand {
        padding: 5px 10px;
        img {
            width: 120px;
        }
    }

    .navbar-header {
        border-bottom: 1px solid #ccc;
        height: 55px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

}
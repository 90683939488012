@font-face {
    font-family: supermarket;
    src: url('/fonts/supermarket.woff2') format('woff2'),
         url('/fonts/supermarket.woff') format('woff'),
         url('/fonts/supermarket.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: supermarketBold;
    src: url('/fonts/supermarket.woff2') format('woff2'),
         url('/fonts/supermarket.woff') format('woff'),
         url('/fonts/supermarket.ttf') format('truetype');
    font-weight: bold;
}
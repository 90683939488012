@import 'main_setting';

$background_color: map-get($footer_custom, background);
$text_color: map-get($footer_custom, text_color);
$text_size: map-get($footer_custom, text_size);
$text_black: map-get($text, title_color);

.footer_custom {
    margin-top: 30px;
    padding-top: 30px;
    background-color: $background_color;
    text-align: center;
    color: $text_color;
    font-size: $text_size;
    border-top: 3px solid map-get($color_theme, orange);

    a {
        color: $text_black;
    }

    .footer_menu {
        padding: 5px 10px;
        border-right: 1px solid  map-get($color_theme, black);

    }
    .end_menu {
        border: none;
    }

    #copyright {
        display: block;
        width: 200px;
        margin: 50px auto 20px;
    }

    @media (max-width: 768px) {
        > div {
            margin-bottom: 15px;
        }
    }

    .icon_tel {
        padding-right: 5px;
    }
}

